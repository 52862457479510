<template>
  <form
    @submit.prevent="saveVehicle"
    autocomplete="off"
    class="w-full max-h-screen bg-background flex flex-col relative"
    :class="{ 'overflow-y-hidden': isBusy }"
  >
    <div
      v-show="isBusy"
      class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10" />
    </div>

    <page-header v-if="isEdit" :title="pageTitle">
      <button
        type="button"
        class="ml-4 flex items-center bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
        @click="cancelEdit"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
        <span>Cancel</span>
      </button>
      <div style="padding-right: 5px"></div>
      <button
        type="submit"
        class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save</span>
      </button>
    </page-header>

    <page-header v-else-if="!isEdit" :title="pageTitle" backTo="fleets">
      <button
        type="submit"
        class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save</span>
      </button>
    </page-header>

    <main class="p-6 bg-white flex-1 overflow-y-scroll">
      <div>
        <div class="flex items-center mb-5">
          <h3
            class="uppercase text-lg font-semibold text-primary tracking-wide"
          >
            Vehicle Information
          </h3>
          <hr class="flex-1 border-primary mx-4" />
        </div>
        <div class="grid grid-cols-3 md:grid-cols-3 grid-flow-col gap-10">
          <div class="row-span-3 col-span-2">
            <div class="flex flex-wrap -my-3 -mx-5">
              <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                  >Manufacturer <span class="text-red-400">*</span></label
                >
                <input
                  v-model="fleet.manufacturer"
                  class="bge-input bge-input-spacing rounded"
                  name="manufacturer"
                  type="text"
                />
              </div>

              <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                  >Assigned To</label
                >
                <input
                  v-model="fleet.assigned_to"
                  class="bge-input bge-input-spacing rounded"
                  name="manufacturer"
                  type="text"
                />
              </div>

              <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                  >Model <span class="text-red-400">*</span></label
                >
                <input
                  v-model="fleet.make"
                  class="bge-input bge-input-spacing rounded"
                  name="make"
                  type="text"
                />
              </div>

              <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                  >Engine Size</label
                >
                <input
                  v-model="fleet.engine_size"
                  class="bge-input bge-input-spacing rounded"
                  name="Engine Size"
                  type="text"
                />
              </div>

              <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                  >Registration <span class="text-red-400">*</span></label
                >
                <input
                  v-model="fleet.license_plate"
                  class="bge-input bge-input-spacing rounded"
                  name="License"
                  type="text"
                />
              </div>

              <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                  >Chassis Number</label
                >
                <input
                  v-model="fleet.chassis_no"
                  class="bge-input bge-input-spacing rounded"
                  name="Chassis"
                  type="text"
                />
              </div>

              <div
                class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3 col-span-4"
              >
                <label class="uppercase font-semibold text-sm">Fuel Type</label>
                <input
                  v-model="fleet.fuel_type"
                  class="bge-input bge-input-spacing rounded"
                  name="Fuel"
                  type="text"
                />
              </div>

              <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                  >Tax Expiry <span class="text-red-400">*</span></label
                >
                <Calendar
                  v-model="fleet.tax_expiry"
                  dateFormat="dd/mm/yy"
                  :showIcon="true"
                />
              </div>
              <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                  >Colour <span class="text-red-400">*</span></label
                >
                <input
                  v-model="fleet.colour"
                  class="bge-input bge-input-spacing rounded"
                  type="text"
                />
              </div>
              <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                  >Tax Expiry Notification Email<span class="text-red-400"
                    >*</span
                  ></label
                >
                <AutoComplete
                  :multiple="true"
                  v-model="fleet.tax_reminder_recipient"
                  :suggestions="filteredStaffs"
                  @complete="searchStaffs($event)"
                  field="name"
                  class="w-full"
                  minLength="3"
                />
              </div>
              <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                  >Year Manufactured <span class="text-red-400">*</span></label
                >
                <Calendar
                  v-model="fleet.manufacture_year"
                  view="year"
                  dateFormat="yy"
                />
              </div>
              <div class="w-full sm:w-1/2 lg:w-1/2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                  >Seating Capacity</label
                >
                <input
                  v-model="fleet.seat_capacity"
                  class="bge-input bge-input-spacing rounded"
                  name="capacity"
                  type="text"
                />
              </div>
            </div>
          </div>

          <div class="row-span-3">
            <div class="flex flex-wrap -my-3 -mx-5">
              <div
                class="w-full sm:w-2/2 lg:w-2/2 flex flex-col px-5 mb-3 row-span-full"
              >
                <label class="uppercase font-semibold text-sm"
                  >Purchase Date</label
                >
                <Calendar
                  v-model="fleet.purchase_date"
                  dateFormat="dd/mm/yy"
                  :showIcon="true"
                />
              </div>

              <div class="w-full sm:w-2/2 lg:w-2/2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm"
                  >Purchase Mileage</label
                >
                <input
                  v-model="fleet.purchase_mileage"
                  class="bge-input bge-input-spacing rounded"
                  name="Fuel"
                  type="text"
                />
              </div>
              <div class="w-full sm:w-2/2 lg:w-2/2 flex flex-col px-5 mb-3">
                <label class="uppercase font-semibold text-sm">Notes</label>
                <textarea
                  v-model="fleet.notes"
                  class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-3 px-4 focus:shadow w-full"
                  placeholder="Note..."
                  rows="10"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </form>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import Spinner from "@/components/Spinner.vue";
const Datepicker = () => import("vuejs-datepicker");
import notificationMixin from "@/mixins/notificationMixin";
export default {
  mixins: [notificationMixin],
  name: "CreateVehicle",
  props: {
    fleetId: String,
  },
  components: {
    PageHeader,
    Spinner,
    Datepicker,
  },
  data() {
    return {
      isBusy: false,
      pageTitle: "Create Vehicle",
      isEdit: false,
      fleet: {},
      filteredStaffs: null,
    };
  },

  //Todo : create get vehicle id service and remove below
  async mounted() {
    this.$refs.tbidentification.focus();
  },
  methods: {
    formatUnixFn: (v) => {
      return v == "" ? "" : this.$moment(v).unix();
    },

    async searchStaffs(event) {
      try {
        let query = event.query.toLowerCase();
        let results = await this.HolidayService.searchStaff(query, {
          select: "staff_id,first_name,last_name",
        });

        if (results && results.data) {
          this.filteredStaffs = results.data.map((staff) => ({
            staff_id: staff.staff_id,
            name: `${staff.first_name} ${staff.last_name}`,
          }));
        } else {
          return [];
        }
      } catch (error) {
        console.error("Error fetching staff data:", error);
        return [];
      }
    },

    async saveVehicle() {
      // First validate the input fields
      const validationError = this.validateFleetInput();
      if (validationError) {
        this.notifyWarning(validationError);
        return;
      }

      // Prepare the fleet data before sending to the server
      this.prepareFleetData();

      // Attempt to save the fleet via API call
      try {
        const response = await this.handleApiRequest(
          this.FleetService.createFleet(this.fleet),
          false,
        );

        // Assuming response contains the fleet_id when successful
        if (response && response.data && response.data.fleet_id) {
          this.notifySuccess("Vehicle saved successfully.");
          await this.$store.dispatch(
            "setLastSelectedFleet",
            response.data.fleet_id,
          );
          await this.$router.push({
            name: "fleets-edit",
            params: { fleetId: response.data.fleet_id, isCreateResult: true },
          });
        }
      } catch (error) {
        // Error handling is already performed in handleApiRequest
      }
    },

    validateFleetInput() {
      const fields = [
        { key: "manufacturer", message: "Please enter manufacturer" },
        { key: "make", message: "Please enter model" },
        { key: "license_plate", message: "Please enter registration" },
        { key: "tax_expiry", message: "Please enter tax expiry" },
        { key: "colour", message: "Please enter colour" },
        { key: "manufacture_year", message: "Please enter manufacture year" },
      ];

      for (let { key, message } of fields) {
        if (!this.fleet[key]) {
          return message; // Return the first encountered validation message
        }
      }

      if (
        !Array.isArray(this.fleet.tax_reminder_recipient) ||
        this.fleet.tax_reminder_recipient.length === 0
      ) {
        return "Please add at least one tax reminder recipient";
      }
      return null; // No validation errors found
    },

    prepareFleetData() {
      // year to YYYY format
      this.fleet.manufacture_year = this.fleet.manufacture_year
        ? this.$moment(this.fleet.manufacture_year).format("YYYY")
        : null;
    },

    cancelEdit: function () {
      this.$router.push({
        name: "fleets-edit",
        params: { fleetId: this.fleet.fleet_id },
      });
    },
  },
};
</script>
